import {
  MainPageParagraph,
  MainPageStyle,
  MainPageUnorderedList,
  MainPageListItem,
  MainPageParagraphHeader,
} from "./MainPageStyled";

const MainPage = () => {
  return (
    <MainPageStyle>
      <MainPageParagraph>
        Создан в 1979 году. Сотрудники центра квалифицированный персонал - психиатры, детские
        психиатры, психотерапевты, наркологи, логопеды, дефектологи, психологи, невропатологи,
        педиатры.
      </MainPageParagraph>
      <MainPageParagraph>
        Лечение проводит кандидат медицинских наук, врач-психиатр высшей категории, детский
        психиатр, психотерапевт, нарколог.
      </MainPageParagraph>
      <MainPageParagraph>
        С начала основания центра, квалифицированную помощь получили более 5 млн взрослых и детей,
        которые вернулись к здоровой и полноценной жизни.
      </MainPageParagraph>
      <MainPageParagraph>
        В основе нашего лечения классическая НЕМЕДИКАМЕНТОЗНАЯ терапия (комплекс
        психофизиологической реабилитации) которую к сожалению, никто кроме специалистов нашего
        центра не проводит. Все что могут нынешние «горе – деятели» это соревноваться в назначении
        тяжелейших препаратов, которые очень быстро переведут Вас и Вашего ребенка в вегетативное
        состояние.
      </MainPageParagraph>
      <MainPageParagraph>
        Что касается лечения проводимого в нашем центре для аутистов, детей с ЗПР, различными
        пограничными расстройствами, многообразию форм нашего воздействия в детской и подростковой
        клинике служат специальные психотерапевтические методы и приемы, а также мероприятия,
        направленные на оздоровление внешней среды, которые ни в коем случае нельзя суживать до
        симптоматического лечения.
      </MainPageParagraph>
      <MainPageParagraph>
        Дети и подростки, как уже говорилось выше, находятся в состоянии постоянного развития. У них
        развивается интеллект, формируются связи с внешним миром, вырабатываются социальные
        установки, складывается характер. Все эти процессы могут нарушиться под влиянием болезни,
        нередко приводя к искажению развития, способствуя неправильному формированию личности.
      </MainPageParagraph>
      <MainPageParagraph>
        Кроме того, в нашем центре мы проводим лечение и сеансы лечебно-восстановительной
        немедикаментозной терапии, для взрослых пациентов, а также родителей детей, которые в
        следствии болезни ребенка зачастую находятся в тяжелых тревожно-депрессивных состояниях, у
        них часты неврологические проблемы в виде ВСД, воспалений тройничного нерва, а также
        депрессий и развивающихся в следствии него:
      </MainPageParagraph>
      <MainPageUnorderedList>
        <MainPageListItem>алкоголизма</MainPageListItem>
        <MainPageListItem>наркомании</MainPageListItem>
        <MainPageListItem>никотиномании</MainPageListItem>
      </MainPageUnorderedList>
      <MainPageParagraph>
        Это необходимо учитывать при лечении, особенно в эру слишком большого увлечения
        психофармакологическими средствами, действие которых на болезненные симптомы и синдромы
        относительно изучено, но возможность их отрицательного влияния на развивающийся детский мозг
        пока еще остается «белым пятном».
      </MainPageParagraph>
      <MainPageParagraph>
        Кроме того не стоит забывать о тех проблемах которые приносит прием психотропов , ноотропов
        и прочей химии, одними из которых являются разнообразные и тяжелейшие поражения:
      </MainPageParagraph>
      <MainPageUnorderedList>
        <MainPageListItem>печени</MainPageListItem>
        <MainPageListItem>почек</MainPageListItem>
        <MainPageListItem>поджелудочной железы</MainPageListItem>
        <MainPageListItem>сердечно сосудистой системы</MainPageListItem>
      </MainPageUnorderedList>
      <MainPageParagraph>
        генномутирующее действие которое оказывает на организм прием таких модных сейчас химических
        соединений, с легкостью назначаемые Вашему ребенку очередным «горе специалистом»
      </MainPageParagraph>
      <MainPageParagraph>
        Поэтому мы рекомендуем в тех случаях, когда имеются показания и возможность применения
        психотерапии и педагогического воздействия для оказания помощи больному, начинать лечение с
        этих методов.
      </MainPageParagraph>

      <MainPageParagraphHeader>Мы поможем Вам с такими заболеваниями</MainPageParagraphHeader>
    </MainPageStyle>
  );
};

export default MainPage;
